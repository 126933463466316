



import Vue from "vue";
import { mapState } from "vuex";
import { request } from "@/api";
import { auth } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState(["user"])
  },
  mounted() {
    if (this.user.isSignIn) {
      request({
        url: "https://chat.angrymiao.ai/v1/user/token",
        method: "post",
        data: {
          access_token: auth.getToken(),
          refresh_token: auth.getRefreshToken(),
          access_info: {}
        }
      }).then((res: any) => {
        if (res.retcode === 200) {
          location.href = `https://chat.angrymiao.ai/login?access_token=${auth.getToken()}&refresh_token=${auth.getRefreshToken()}`;
        } else {
          this.$message.info(res.retcode + ": " + res.retmsg, 5000);
          setTimeout(() => {
            location.href = `https://chat.angrymiao.ai/login?access_token=${auth.getToken()}&refresh_token=${auth.getRefreshToken()}`;
          }, 5000);
        }
      });
    } else {
      dispatch.userSignIn();
    }
  }
});
